var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('ClienteModal',{attrs:{"modal":_vm.clieModal},on:{"cerrarModal":function($event){_vm.clieModal=false},"child-refresh":function($event){_vm.refrescarCliente=true}}}),_c('ActividadModal',{attrs:{"modal":_vm.actModal},on:{"cerrarModal":function($event){_vm.actModal=false},"child-refresh":function($event){_vm.refrescarActividad=true}}}),_c('ClienteTransaccionesModal',{attrs:{"modal":_vm.clietraModalCreate,"ClientItem":_vm.ClientItem,"title":_vm.titleModal},on:{"update:modal":function($event){_vm.clietraModalCreate=$event},"close":_vm.closeModal}}),_c('ActividadClientesModal',{attrs:{"modal":_vm.actClieModal},on:{"cerrarModal":function($event){_vm.actClieModal=false},"child-refresh":function($event){_vm.refrescarActividad=true},"getDataId":_vm.getDataId}}),_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.clientsActivitiesList')))])]),_c('CCardBody',[_c('CustomTabs',{attrs:{"active-tab":_vm.Tab},on:{"update:activeTab":_vm.handleTab}},[_c('CustomTab',{attrs:{"title":_vm.$t('label.client'),"icon":"user"}},[_c('CRow',{staticClass:"mt-2"},[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.client'),
                      placement: 'top-end'
                  }),expression:"{\n                      content: $t('label.client'),\n                      placement: 'top-end'\n                  }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":function($event){_vm.clieModal=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.itemsCliente,"fields":_vm.fieldsCliente,"loading":_vm.LoadingCliente,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":5,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.transactions')+' '+_vm.$t('label.client'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.transactions')+' '+$t('label.client'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.clietraModal(item)}}},[_c('CIcon',{attrs:{"name":"clienteTransacciones"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.activities')+' '+_vm.$t('label.client'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.activities')+' '+$t('label.client'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"plan","size":"sm"},on:{"click":function($event){return _vm.abrirActividad(item)}}},[_c('CIcon',{attrs:{"name":"clientesActividad"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.edit')+' '+_vm.$t('label.client'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.edit')+' '+$t('label.client'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){_vm.clieModal=item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('CustomTab',{attrs:{"title":_vm.$t('label.AssociationActivityClient'),"icon":"actividadesCliente"}},[_c('CRow',{staticClass:"mt-2"},[_c('CCol',{staticClass:"form-inline",attrs:{"sm":"7"}},[_c('label',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('label.activity'))+" ")]),_c('v-select',{staticClass:"w-75",attrs:{"filter":_vm.fuseSearchActividades,"options":_vm.actividadesOptions,"placeholder":_vm.$t('label.select')},on:{"input":_vm.isActivitycleanData},model:{value:(_vm.actividad),callback:function ($$v) {_vm.actividad=$$v},expression:"actividad"}})],1),_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"5"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.Association'),
                      placement: 'top-end'
                  }),expression:"{\n                      content: $t('label.Association'),\n                      placement: 'top-end'\n                  }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":function($event){return _vm.abrirActividadClientes(true)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.ComputedClientlist,"fields":_vm.fieldsActividadCliente,"loading":_vm.LoadingActividad,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":5,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Details",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.Inactivate')+' '+_vm.$t('label.Association'),
                            placement: 'top-end'
                          }),expression:"{\n                            content: $t('label.Inactivate')+' '+$t('label.Association'),\n                            placement: 'top-end'\n                          }"}],attrs:{"shape":"square","color":"wipe","size":"sm"},on:{"click":function($event){return _vm.cambiarEstatus(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }